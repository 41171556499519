import modify from "assets/images/landing/transactional/modify.png";
import integration from "assets/images/landing/transactional/integration.png";
import scale from "assets/images/landing/transactional/scale.png";
import track from "assets/images/landing/transactional/track.png";

const data = {
  features: [
    {
      icon: modify,
      tagline: "Modify",
      title: "Make Changes On the Go Without Waiting on Developers",
      description:
        "Take full control of your messaging - adapt your content to market trends and make real-time modifications when your customers need them, without depending on code changes.",
      benefits: ["100% content ownership", "Zero code dependence", " Increased revenue opportunities"],
      bgColor: "#FFBF6033",
      color: "#FFBF60",
      href: "https://app.go-mailer.com/register",
      cta: "Get started free",
    },
    {
      icon: scale,
      tagline: "Scale",
      title: "Send Unlimited Emails Without Restrictions",
      description:
        "Scale your business effortlessly and reach your users without limits or hidden fees. Send unlimited emails with confidence, guaranteed inbox placement, and instant delivery.",
      benefits: ["100% transparency", "Guaranteed inbox placement", "Instant and reliable email delivery"],
      bgColor: "#2A99FF33",
      color: "#2A99FF",
      href: "https://app.go-mailer.com/register",
      cta: "Get started free",
      direction: "rtl",
    },
    {
      icon: track,
      tagline: "track",
      title: "Optimize performance with deep insights and delivery logs",
      description:
        "Know exactly what’s happening with your transactional emails in real time. Monitor opens, clicks, and delivery performance with in-depth reporting and up to 90 days delivery log, so you’re always in control.",
      benefits: ["Real-time insights", "Delivery investigation", "Increased engagements"],
      bgColor: "#66B10733",
      color: "#66B107",
      href: "https://app.go-mailer.com/register",
      cta: "Get started free",
    },
    {
      icon: integration,
      tagline: "Integration",
      title: "Start Driving Instant Results with Quick & Seamless Integration",
      description:
        "Start sending emails in minutes with seamless integration. Keep your workflow smooth and stay connected to your customers without disruptions to your existing systems.",
      benefits: ["Zero disruptions", "Zero technical debt", "Hassle-free integrations"],
      bgColor: "#2A99FF33",
      color: "#2A99FF",
      href: "https://app.go-mailer.com/register",
      cta: "Get started free",
      direction: "rtl",
    },
  ],
  faq: [
    {
      question: "How does Go-Mailer ensure my transactional emails don’t go to spam?",
      answer:
        "We guarantee 99.99% inbox placement by using dedicated IPs, authentication protocols (SPF, DKIM, DMARC), and best-in-class email infrastructure. This means your confirmation emails, receipts, and notifications always land in the inbox, building trust with your customers and ensuring critical messages are never missed.",
    },
    {
      question: "Is there a limit on how many transactional emails I can send?",
      answer:
        "There are no limits to how many transactional emails you can send. Go-Mailer allows you to send unlimited transactional emails with instant delivery and guaranteed inbox placement. Whether you're sending thousands or millions of emails, our platform scales effortlessly with your business.",
    },
    {
      question: "Do I need a developer to make changes to my transactional emails?",
      answer:
        "The simple answer is no. With Go-Mailer, your marketing team has full control over your email content. You can update your messaging, add personalization, and optimize for upsell opportunities, all without touching a single line of code. This means faster updates, more flexibility, and increased revenue potential without relying on developers.",
    },
  ],
};

export default data;
