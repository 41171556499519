import inbox from "assets/images/landing/campaign/inbox.png";
import report from "assets/images/landing/campaign/report.png";
import resend from "assets/images/landing/campaign/resend.png";
import personalisation from "assets/images/landing/campaign/personalisation.png";

const data = {
  features: [
    {
      icon: inbox,
      tagline: "inbox",
      title: "Drive Engagement & Build Lasting Customer Relationships",
      description:
        "Avoid spam folders and build trust with every email. Deliver personalized messages that engage, convert, strengthen your brand reputation, and maximize your customer lifetime value.",
      benefits: ["Guaranteed inbox delivery", "More opens, clicks, and sales", "Stronger brand reputation"],
      bgColor: "#FFBF6033",
      color: "#FFBF60",
      href: "https://app.go-mailer.com/register",
      cta: "Get started free",
    },
    {
      icon: resend,
      tagline: "resend",
      title: "Recapture Lost Engagement & Turn Missed Opens into Revenue",
      description:
        "Low open rates? Not a problem! Turn missed opportunities into revenue with our one-click resend to non-openers. Recover lost engagement and effortlessly maximize your ROI.",
      benefits: ["No double charges", "16% higher open rates", "Reduced list-fatigue"],
      bgColor: "#2A99FF33",
      color: "#2A99FF",
      href: "https://app.go-mailer.com/register",
      cta: "Get started free",
      direction: "rtl",
    },
    {
      icon: personalisation,
      tagline: "personalisation",
      title: "Build Deeper Connections with Personalized Messages",
      description:
        "Deliver hyper-personalized emails that resonate deeply with each customer, driving repeat sales and higher engagement. Leverage customer data to craft messages that convert relationships into revenue.",
      benefits: ["Custom attribute support", "Increased engagement & conversions", "Stronger customer relationships"],
      bgColor: "#66B10733",
      color: "#66B107",
      href: "https://app.go-mailer.com/register",
      cta: "Get started free",
    },
    {
      icon: report,
      tagline: "report",
      title: "Make Smarter Decisions with Real-Time Insights",
      description:
        "Gain instant visibility into your campaign performance with real-time open rates, clicks, and subscriber behavior tracking. Use data-backed insights to fine-tune your strategies and drive better results.",
      benefits: [
        "Real-time performance data",
        "Detailed delivery, clicks, and opens logs",
        "Demographic distributions",
      ],
      bgColor: "#66B10733",
      color: "#66B107",
      href: "https://app.go-mailer.com/register",
      cta: "Get started free",
      direction: "rtl",
    },
  ],
  faq: [
    {
      question: "Does a campaign mean sending an email to just one customer?",
      answer:
        "No. A campaign is a strategic series of emails sent to specific groups of customers who share similar characteristics. These coordinated messages are designed with specific goals like nurturing relationships, re-engaging inactive customers, or promoting special offers. Campaigns allow you to deliver relevant content to the right audiences at planned intervals, rather than manually communicating with just one person at a time.",
    },
    {
      question: "How can I ensure my emails land in inboxes and not the spam folder?",
      answer:
        "Our platform guarantees inbox delivery by optimizing sender reputation, automatically removing unengaged subscribers, and ensuring compliance with email authentication protocols (SPF, DKIM, DMARC), and dedicated mailing servers. This means more of your messages reach the right audience, leading to higher open rates, stronger customer trust, and increased conversions.",
    },
    {
      question: "What if my emails have low open rates? Can I recover lost engagement?",
      answer:
        "Absolutely! Our one-click resend to non-openers feature helps you recover missed opportunities by automatically resending emails to non-openers. This simple yet powerful tool helps boost engagement, increase conversions, and maximize your ROI, turning lost opens into revenue.",
    },
  ],
};

export default data;
