import logo from "assets/icons/logo-new-white-orange.png";
import { Button } from "shared/button";
import styles from "./header.module.css";
import config from ".config.js";

export default function Header() {
  const navigateTo = (path) => {
    window.location.href = `${config.app_url}/${path}`;
  };

  return (
    <div className={styles.headerLayout}>
      <div className={styles.brandingArea}>
        <a href="/developers" className={styles.siteLink}>
          <img src={logo} alt="Go-Mailer Icon" className={styles.siteIcon} />
          <div className={styles.siteName} aria-labelledby="Site Name">
            Go-Mailer<span className={styles.stub}>.</span>
          </div>
        </a>
      </div>
      <div className={styles.actions}>
        <Button text="Log In" className={styles.loginBtn} onClick={() => navigateTo("login")} />
        <Button text="Sign Up" className={styles.signUpBtn} onClick={() => navigateTo("register")} />
      </div>
    </div>
  );
}
