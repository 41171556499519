import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "assets/icons/logo-new-white-orange.png";
import twitter from "assets/svg/icons-twitter.svg";
import instagram from "assets/svg/icons-instagram.svg";
import facebook from "assets/svg/icons-facebook.svg";
import linkedin from "assets/svg/icons-linkedin.svg";
import styles from "./footer.module.css";
import generalStyle from "app.module.css";
import { EmailInput } from "elements/inputs";
import * as userService from "services/user";

export const Footer = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = () => {
    userService
      .createContact(`email=${email}&tags=subscriber`)

      .then((response) => {
        const {
          data: { error },
        } = response;
        if (error) {
          return;
        }
        setEmail("");
      });
  };
  return (
    <footer className={styles.footer}>
      <div className={generalStyle.gmContainer}>
        <div className={styles.detail}>
          <div className={styles.info}>
            <div className={styles.siteInfo}>
              <img alt="Go-Mailer Icon" src={logo} className={styles.siteIcon} />
              <span>Go-Mailer. </span>
            </div>
            <p>Stay up to date with marketing and productivity updates.</p>
            <div className={styles.formInput}>
              <EmailInput text="Subscribe" value={email} onInput={(value) => setEmail(value)} onSubmit={handleSubmit} />
            </div>
          </div>
          <div className={styles.footerColumn}>
            <div className={styles.footerCol}>
              <h4 className={styles.colHead}>Products</h4>
              <a href="/products/campaign/">Email Marketing</a>
              {/* <a href="/products/subscriber-management/">Subscriber Management</a> */}
              {/* <a href="/products/user-management/">User Management</a> */}
              <a href="/products/transactionals">Transactional Emails</a>
              <a href="/products/automation">Automations</a>
              <a href="/products/analytics">App Analytics</a>
            </div>
            <div className={styles.footerCol}>
              <h4 className={styles.colHead}>Company</h4>
              {/* <a href="https://go-mailer.blog/about/">About</a> */}
              <a href="https://go-mailer.blog">Blog</a>
              <a href="/developers">Developers</a>
            </div>
            <div className={styles.footerCol}>
              <h4 className={styles.colHead}>Legal</h4>
              <Link to="/terms">Terms of Use </Link>
              <Link to="/privacy">Privacy Policy</Link>
            </div>
          </div>
        </div>
        <div className={styles.footnote}>
          <div className={styles.socialIcons}>
            <a href="https://www.linkedin.com/company/go-mailer/" target="_blank" rel="noreferrer">
              <img alt="LinkedIn Icon" src={linkedin} />
            </a>
            <a href="https://www.instagram.com/gomailer_ltd/" target="_blank" rel="noreferrer">
              <img alt="Instagram Icon" src={instagram} />
            </a>
            <a href="https://www.facebook.com/gomailer_ltd/" target="_blank" rel="noreferrer">
              <img alt="Instagram Icon" src={facebook} />
            </a>
            <a href="https://twitter.com/gomailer_ltd" target="_blank" rel="noreferrer">
              <img alt="Twitter Icon" src={twitter} />
            </a>
          </div>
          <span className={styles.copyright}>copyright &copy; {new Date().getFullYear()} Go-Mailer Limited.</span>
        </div>
      </div>
    </footer>
  );
};
