import React, { useRef, useState } from "react";
import logo from "assets/icons/logo-new.png";
import styles from "./header.module.css";
import { LandingBtn } from "elements/buttons";
import generalStyle from "app.module.css";
import { Icon } from "@iconify/react";
import { WebMenu } from "shared/menu/web";
import { WebProductMenuContent } from "./menu/web";
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from "react-bootstrap";
import product_menu from "./menu/data/items";

export const Header = () => {
  const header_ref = useRef(null);
  const [activeState, setActiveState] = useState(false);

  const toggleNavButton = () => {
    setActiveState((previousState) => !previousState);
  };

  const WebNav = () => (
    <div className={styles.navLinks}>
      <a href="https://go-mailer.blog" onClick={() => setActiveState(false)}>
        Blog{" "}
      </a>
      <WebMenu label="Products" parent={header_ref}>
        <WebProductMenuContent />
      </WebMenu>
      <a href="/#pricing" onClick={() => setActiveState(false)}>
        Pricing{" "}
      </a>
    </div>
  );

  const MobileNav = () => (
    <div className={styles.navLinks}>
      <a href="https://go-mailer.blog" onClick={() => setActiveState(false)}>
        Blog{" "}
      </a>
      <Accordion>
        <AccordionItem eventKey={0} className={styles.accordionItem}>
          <AccordionHeader className={styles.accordionHeader}>Products</AccordionHeader>
          <AccordionBody className={styles.accordionBody}>
            <div className={styles.subItems}>
              {product_menu.map((menu) => (
                <span key={menu.icon}>
                  <a href={menu.link}>{menu.title}</a>
                </span>
              ))}
            </div>
          </AccordionBody>
        </AccordionItem>
      </Accordion>
      <a href="#pricing" onClick={() => setActiveState(false)}>
        Pricing{" "}
      </a>
    </div>
  );

  return (
    <header ref={header_ref} className={styles.header}>
      <div className={generalStyle.gmContainer}>
        <div className={styles.navWrapper}>
          <div className={styles.siteInfo}>
            <div className={styles.siteBranding}>
              <a href="*" className={styles.siteLink}>
                <img src={logo} alt="Go-Mailer Icon" className={styles.siteIcon} />
              </a>
              <div className={styles.siteName}>
                <a href="/" className={styles.siteLink}>
                  Go-Mailer
                </a>
                <span className={styles.stub}>.</span>
              </div>
            </div>
            <nav className={styles.navBar}>
              <WebNav />
            </nav>
          </div>

          <div className={styles.headBtn}>
            <LandingBtn location="header" />
          </div>
          <div className={styles.options}>
            <Icon icon={`lucide:${activeState ? "x" : "menu"}`} onClick={toggleNavButton} />
          </div>
        </div>
        <div className={`${styles.mobileNav} ${activeState ? styles.showNavbar : ""}`}>
          <div className={styles.mobileNavWrapper}>
            <MobileNav />
            <LandingBtn location="header" />
          </div>
        </div>
      </div>
    </header>
  );
};
