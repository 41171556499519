import React from "react";
import generalStyle from "app.module.css";
import styles from "./getstarted.module.css";
import { LandingBtn } from "elements/buttons";
import { HeadingThree } from "shared/text/header";

export const GetStartedSection = () => {
  return (
    <div className={styles.wrapper}>
      <div className={generalStyle.gmContainer}>
        <div className={styles.contentWrapper}>
          <div className={styles.header}>
            <HeadingThree text="Let’s get you started on success!" />
            <p>
              Get a personalised demo or explore the platform yourself. Take the next step towards smarter
              communication, increased engagement, and higher revenue growth..
            </p>
          </div>
          <LandingBtn align="center" location="get started" />
        </div>
      </div>
    </div>
  );
};
