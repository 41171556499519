import cx from "classnames";
import { Icon } from "assets/icons";
import React from "react";
import styles from "./major.module.css";

export const MajorFeature = ({
  icon,
  tagline = "Feature title",
  title = "Feature title",
  benefits = [],
  description = "Feature description",
  href,
  direction = "ltr",
  cta,
}) => {
  return (
    <div className={cx(styles.feature, styles[direction])}>
      <div className={styles.featureContent}>
        {/* <span className={styles.tag}>{tagline}</span> */}
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.description}>{description}</p>
        {benefits && (
          <div className={styles.benefits}>
            {benefits.map((benefit) => (
              <div key={benefit} className={styles.benefit}>
                <Icon name="check" width={20} height={20} className={styles.benefitIcon} /> <span>{benefit}</span>
              </div>
            ))}
          </div>
        )}
        <div className={styles.actionBtn}>
          <a href={href} className={styles.getStarted}>
            {cta}
          </a>
          <Icon name="arrow_right_flat" className={styles.icon} />
        </div>
      </div>
      <div className={styles.imageContainer}>
        <img src={`${icon}`} className={styles.icon} alt={tagline} />
      </div>
    </div>
  );
};
