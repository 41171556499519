import { Partners } from "pages/main/partners";
import { MajorFeature } from "shared/feature/major";
import { LandingPageHero } from "shared/hero";
import data from "./data.js";
import React from "react";
import { MainFeaturesWrapper } from "shared/feature/wrapper";
import { Footer } from "pages/footer";
import { GetStartedSection } from "pages/main/get-started";
import { FAQ } from "shared/faq";
import styles from "./analytics.module.css";
import analytics_main from "assets/images/landing/analytics/analytics-main.png";

export const AnalyticsPage = () => {
  return (
    <>
      <LandingPageHero
        className={styles.wrapper}
        image={analytics_main}
        title="Turn Raw Data Into Powerful Insights For Business Growth"
        description="Track how your app is used, compare usage trends, and analyze your customers’ behavior to refine your product strategies and maximize engagement."
      />
      <Partners />
      <MainFeaturesWrapper className={styles.features}>
        {(data.features || []).map((feature) => (
          <MajorFeature key={feature.icon} {...feature} />
        ))}
      </MainFeaturesWrapper>
      <FAQ data={data.faq || []} />
      <GetStartedSection />
      <Footer />
    </>
  );
};
