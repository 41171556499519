import React from "react";
import generalStyle from "app.module.css";
import styles from "./transactional.module.css";
import { HeaderLabel, HeadingTwo } from "shared/text/header";
import { Paragraph } from "shared/text/paragraph";
import main_image from "assets/images/landing/happy-customer.png";
import available from "assets/images/landing/icons/available.png";
import server from "assets/images/landing/icons/server.png";
import scale from "assets/images/landing/icons/scale.png";
import { SubFeature } from "shared/feature/sub";

export const TransactionalFeature = () => {
  return (
    <div className={styles.wrapper}>
      <div className={generalStyle.gmContainer}>
        <div className={styles.header}>
          <HeaderLabel text="Notify. Confirm. Engage" />
          <HeadingTwo text="Never Leave Your Customer Hanging - Deliver Instant, Reliable Messages." />
          <Paragraph
            size="medium"
            text="
            Send instant, reliable messages that keep your customers informed at every touchpoint. From OTPs and
            confirmations to alerts, ensure seamless communication that builds trust and boosts engagement when it
            matters most.
          "
          />
        </div>
        <div className={styles.contentWrapper}>
          <div className={styles.imageWrapper}>
            <img alt="Transactional messaging" src={main_image} />
          </div>
          <div className={styles.featureList}>
            <SubFeature
              title="99.9% Service Uptime"
              description="Keep your communications running seamlessly with uninterrupted, always-on messaging."
              image={available}
            />
            <SubFeature
              title="Scale Without Limits"
              description="Effortlessly scale your messaging without compromising on speed, reliability, or delivery."
              image={scale}
            />
            <SubFeature
              title="Dedicated Mailing servers"
              description="Ensure fast, secure, and reliable messaging with 100% dedicated servers."
              image={server}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
