import { SimpleInput } from "elements/inputs";
import { Button } from "shared/button";
import { GridColumn, GridRow } from "shared/layouts/grid";
import styles from "./form.module.css";
import React, { useState } from "react";
import { MultiSelect } from "shared/select";
import { Spacer } from "shared/layouts/generic";
import * as DemoService from "services/demo";
import { Alert } from "shared/form-alert";

export const DemoForm = () => {
  const [alert, setAlert] = useState({});
  const [form_data, setFormData] = useState({});
  const [selected_list_size, setSelectedListSize] = useState({});

  const size_options = [
    { label: "Less than 100K", value: "0:100K" },
    { label: "100K - 250K", value: "100K:250K" },
    { label: "250K-500K", value: "250K:500K" },
    { label: "500K - 1M", value: "500K:1M" },
    { label: "1M - 5M", value: "1M:5M" },
    { label: "Greater than 5M", value: "5M+" },
  ];

  const handleInputChange = (key, value) => {
    if (!key) return;
    setFormData((dt) => ({ ...dt, [key]: value }));
  };

  const submit = async () => {
    if (
      !form_data.email ||
      !form_data.firstname ||
      !form_data.lastname ||
      !form_data.company_name ||
      !form_data.phone_number ||
      !selected_list_size.value
    ) {
      return;
    }

    const {
      data: { payload },
    } = await DemoService.create({ metadata: { ...form_data, subscribers: selected_list_size.value } });
    if (payload) {
      setFormData({});
      setSelectedListSize({});
      setAlert({ message: "Request submitted successfully", type: "success" });
    } else setAlert({ message: "An error occurred. Please try again." });
  };

  return (
    <div className={styles.wrapper}>
      <Alert {...alert} onClose={() => setAlert({})} />
      <GridRow num_of_columns={2}>
        <GridColumn>
          <SimpleInput
            label="First Name"
            required={true}
            value={form_data.firstname}
            onInput={(v) => handleInputChange("firstname", v)}
          />
        </GridColumn>
        <GridColumn>
          <SimpleInput
            label="Last Name"
            required
            value={form_data.lastname}
            onInput={(v) => handleInputChange("lastname", v)}
          />
        </GridColumn>
      </GridRow>
      <Spacer multiple={4} />
      <GridRow num_of_columns={1}>
        <GridColumn>
          <SimpleInput
            label="Company Name"
            required
            value={form_data.company_name}
            onInput={(v) => handleInputChange("company_name", v)}
          />
        </GridColumn>
      </GridRow>
      <Spacer multiple={4} />
      <GridRow num_of_columns={2}>
        <GridColumn>
          <SimpleInput
            label="Company Email"
            required
            value={form_data.email}
            onInput={(v) => handleInputChange("email", v)}
          />
        </GridColumn>
        <GridColumn>
          <SimpleInput
            label="Phone Number"
            required
            value={form_data.phone_number}
            onInput={(v) => handleInputChange("phone_number", v)}
          />
        </GridColumn>
      </GridRow>
      <Spacer multiple={4} />
      <GridRow num_of_columns={1}>
        <GridColumn>
          <MultiSelect
            label="Number of Users (Est.)"
            required
            options={size_options}
            value={selected_list_size}
            onChange={setSelectedListSize}
            isSorted={false}
          />
        </GridColumn>
      </GridRow>
      <Spacer multiple={6} />
      <GridRow num_of_columns={1}>
        <GridColumn>
          <Button
            disabled={
              !form_data.email ||
              !form_data.firstname ||
              !form_data.lastname ||
              !form_data.company_name ||
              !form_data.phone_number ||
              !selected_list_size.value
            }
            className={styles.btn}
            text="Schedule Demo"
            onClick={submit}
          />
        </GridColumn>
      </GridRow>
    </div>
  );
};
