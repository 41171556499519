import React from "react";
import generalStyle from "app.module.css";
import styles from "./insight.module.css";
import { HeaderLabel, HeadingTwo } from "shared/text/header";
import { Paragraph } from "shared/text/paragraph";
import main_image from "assets/images/landing/charts.png";
import analyze from "assets/images/landing/icons/analyze.png";
import compare from "assets/images/landing/icons/compare.png";
import demographic from "assets/images/landing/icons/demographic.png";
import { SubFeature } from "shared/feature/sub";

export const InsightsFeature = () => {
  return (
    <div className={styles.wrapper}>
      <div className={generalStyle.gmContainer}>
        <div className={styles.header}>
          <HeaderLabel text="Analyze. Optimize. Deliver" />
          <HeadingTwo text="See what your users see - track, compare, and optimise conversions." />
          <Paragraph
            size="medium"
            text="
            Gain deeper user insights with real-time data and analytics. Track behaviours, identify trends, and optimise engagement strategies to improve retention, enhance user experience, and drive smarter business decisions.
            "
          />
        </div>
        <div className={styles.contentWrapper}>
          <div className={styles.imageWrapper}>
            <img alt="Charts" src={main_image} />
          </div>
          <div className={styles.featureList}>
            <SubFeature
              title="Visualize Trends"
              description="Visualise your app usage and uncover trends to optimise engagement and drive smarter decisions."
              image={analyze}
            />
            <SubFeature
              title="Compare Events"
              description="Identify drop-offs, refine user flows, and improve conversions through event trend analysis."
              image={compare}
            />
            <SubFeature
              title="Track Website Visits"
              description="Track page visits and clicks to optimise engagement, experiences, and boost conversions."
              image={demographic}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
