import React, { useState } from "react";
import styles from "./reviews.module.css";
import generalStyle from "app.module.css";
import quote from "assets/images/quote.png";
import { allReviews } from "./data";

export const Reviews = () => {
  const [curr_index] = useState(Math.floor(Math.random() * allReviews.length));

  return (
    <section className={styles.review}>
      <div className={generalStyle.gmContainer}>
        <div className={styles.revewContainer}>
          <div className={styles.openQuote}>
            <img src={quote} alt="open quote" />
          </div>
          <div className={styles.revewList}>
            <div key={allReviews[curr_index].name} className={styles.testimonyCard}>
              <p>{allReviews[curr_index].content}</p>
              <div className={styles.testimonyAuthor}>
                <div className={styles.testimonyImage}>
                  <img
                    className={styles.authorIcon}
                    style={{ backgroundColor: allReviews[curr_index].color }}
                    src={allReviews[curr_index].image}
                    alt={allReviews[curr_index].company}
                  />
                </div>
                <div className={styles.authorInfo}>
                  <h4>{allReviews[curr_index].name}</h4>
                  <h5>{allReviews[curr_index].company}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
