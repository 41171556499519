/** */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Button } from 'shared/button';
import { Alert } from 'shared/form-alert';

import { unsubscribe } from 'services/user';
import styles from '../guest.module.css';

export const Unsubscribe = ({ setShowLogo }) => {
  const { email, id, tracking_id } = useParams();

  const [selection, setSelection] = useState('');
  const [loading, setLoading] = useState('');
  const [alert, setAlert] = useState({ message: '' });

  useEffect(() => {
    setShowLogo(false);
  });

  const submit = () => {
    const [tenant_part, campaign_part, audience_part, contact_part] = tracking_id.split('.');
    const audience_id = selection === '*' ? '*' : audience_part;
    const t_id = `${tenant_part}.${campaign_part}.${audience_id}.${contact_part}`;

    unsubscribe(t_id, {}, { email, id })
      .then((response) => {
        const { error } = response;
        if (error) {
          return setAlert({ message: 'Could not set your preferences. Please try again.' });
        }
        setAlert({ message: 'Preferences set successfully.', type: 'success' });
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className={styles.wrapper}>
    <div className={styles.formWrapper}>
        <Alert {...alert} onClose={() => setAlert({ message: '' })} />
        <div className={styles.preferencesWrapper}>
          <h5>Set preferences for {email}</h5>
          <div className={styles.selections}>
            <div className={styles.checkboxGroup}>
              <input
                type="radio"
                value=""
                checked={selection === ''}
                name="selection"
                id="one"
                onChange={() => setSelection('')}
                />
              <label htmlFor="one">Unsubscribe from current mailing list.</label>
            </div>
            <div className={styles.checkboxGroup}>
              <input
                type="radio"
                value="*"
                checked={selection === '*'}
                name="selection"
                id="all"
                onChange={() => setSelection('*')}
                />
              <label htmlFor="all">Unsubscribe from all mailing lists.</label>
            </div>
          </div>
        </div>
        <Button
          className={styles.formBtn}
          icon_name="check_circle"
          onClick={submit}
          text="Save Preferences"
          loading={loading}
        />
      </div>
    </div>
  );
};
