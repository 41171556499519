import { Partners } from "pages/main/partners";
import { MajorFeature } from "shared/feature/major";
import { LandingPageHero } from "shared/hero";
import data from "./data.js";
import React from "react";
import { MainFeaturesWrapper } from "shared/feature/wrapper";
import { Footer } from "pages/footer";
import { GetStartedSection } from "pages/main/get-started";
import { FAQ } from "shared/faq";
import styles from "./transactional.module.css";
import main_image from "assets/images/landing/transactional/transactional-main.png";

export const TransactionalPage = () => {
  return (
    <>
      <LandingPageHero
        className={styles.wrapper}
        image={main_image}
        title="Built For Marketing Teams. Easy For Developers"
        description="Enable increased customer engagement and revenue opportunities with reliable transactional email delivery that is easy to set up and fully controlled by the marketing team."
      />
      <Partners />
      <MainFeaturesWrapper className={styles.features}>
        {(data.features || []).map((feature) => (
          <MajorFeature key={feature.icon} {...feature} />
        ))}
      </MainFeaturesWrapper>
      <FAQ data={data.faq || []} />
      <GetStartedSection />
      <Footer />
    </>
  );
};
