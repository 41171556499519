import React from "react";
import styles from "./header.module.css";

export const HeaderLabel = ({ text = "" }) => {
  return <div className={styles.label}>{text}</div>;
};

export const HeadingTwo = ({ text = "" }) => {
  return <h2 className={styles.h2}>{text}</h2>;
};

export const HeadingThree = ({ text = "" }) => {
  return <h3 className={styles.h3}>{text}</h3>;
};
