import { Icon } from "assets/icons";
import React from "react";
import { GridRow } from "shared/layouts/grid";
import data from "../data/items";
import styles from "./web.module.css";
import product_menu_image from "assets/images/landing/product-menu.png";
import { LinkButton } from "shared/button";

const NavItem = ({ icon, title, description, link }) => {
  const handleClick = () => {
    window.location.href = link;
  };

  return (
    <div className={styles.navItem} onClick={handleClick}>
      <div>
        <Icon className={styles.icon} width={16} height={16} name={icon} />
      </div>
      <div className={styles.itemDetails}>
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
};

export const WebProductMenuContent = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.textWrapper}>
        <GridRow num_of_columns={2}>
          {data.map((item) => (
            <NavItem key={item.title} {...item} />
          ))}
        </GridRow>
      </div>
      <div className={styles.imageWrapper} style={{ backgroundImage: product_menu_image }}>
        <div className={styles.imageAreaContent}>
          <div>
            <h3>The Product Marketer's Toolbox.</h3>
            <p>
              All-in-one product marketing platform that delivers exceptional customer engagement and revenue growth.
            </p>
          </div>
          <LinkButton className={styles.linkBtn} text="Get a demo" path="/demo" />
        </div>
      </div>
    </div>
  );
};
