import React from "react";
import cx from "classnames";
import config from ".config";
import styles from "./button.module.css";
import { Icon } from "assets/icons";

export const Button = ({ className = "", icon_name = "", text = "Button", onClick = () => {} }) => {
  return (
    <button className={cx(styles.button, className)} onClick={onClick}>
      {text}
      {icon_name && <Icon className={styles.icon} name={icon_name} />}
    </button>
  );
};

export const CTAButton = ({ text = "Get Started Today", className = "" }) => {
  const handleCTAClick = () => {
    window.location = `${config.app_url}/register`;
  };

  return <Button text={text} className={cx(styles.cta, className)} onClick={handleCTAClick} />;
};

export const LandingBtn = ({ align = "left", location = "" }) => {
  const handleGetDemo = () => {
    window.location = `${config.guest_url}/demo`;
  };

  const handleSignUpAction = () => {
    const utm_query = window.localStorage.getItem("utm");
    window.location = `${config.app_url}/register?${utm_query}&location=${location}`;
  };

  return (
    <div className={cx(styles.actions, styles[align])}>
      <Button text="Get a demo" className={styles.loginBtn} onClick={handleGetDemo} />
      <Button text="Get started free" className={styles.signUpBtn} onClick={handleSignUpAction} />
    </div>
  );
};
