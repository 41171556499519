import React from "react";
import styles from "./feature.module.css";

export const Feature = ({ title = "", description = "", image = null }) => {
  return (
    <div className={styles.feature}>
      <div>
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
      {image && (
        <div className={styles.image}>
          <img alt={title} src={image} />
        </div>
      )}
    </div>
  );
};
