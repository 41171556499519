import React from "react";
import styles from "./sub.module.css";

export const SubFeature = ({ title = "", description = "", image = null }) => {
  return (
    <div className={styles.feature}>
      {image && (
        <div className={styles.featureImage}>
          <img alt={title} src={image} />
        </div>
      )}
      <div className={styles.featureContent}>
        <h4>{title}</h4>
        <p>{description}</p>
      </div>
    </div>
  );
};
