import dotenv from "dotenv";
dotenv.config();

const { REACT_APP_ENV } = process.env;

if (REACT_APP_ENV !== "development") {
  console.log = () => {};
}

const config = {
  development: {
    app_url: "http://localhost:3000",
    guest_url: "http://localhost:3001",
    messaging_uri: "http://localhost:4014",
    users_uri: "http://localhost:4001",
    sales_uri: "http://localhost:4003",
    api_key: "TmF0aGFuLTE1MTA0MzM1NTkzMTIuOTA1OC0x",
    audience_id: 11695389154040,
  },
  testing: {
    app_url: "https://app.gm-g6.xyz",
    guest_url: "https://gm-g6.xyz",
    messaging_uri: "https://messaging.gm-g6.xyz",
    users_uri: "https://users.gm-g6.xyz",
    sales_uri: "https://sales.gm-g6.xyz",
    api_key: "RGFtaWxvbGEtNjc4MzkyNDA1MjIzLjg4Mi03Nw==",
    audience_id: 771695724343512,
  },
  staging: {
    app_url: "https://app.gm-g7.xyz",
    guest_url: "https://gm-g7.xyz",
    messaging_uri: "https://messaging.gm-g7.xyz",
    users_uri: "https://users.gm-g7.xyz",
    sales_uri: "https://sales.gm-g7.xyz",
    api_key: "RGFtaWxvbGEtOTUxMzE3MTI5NjM0LjkzOTUtMjI=",
    audience_id: 221695720350461,
  },
  production: {
    app_url: "https://app.go-mailer.com",
    guest_url: "https://go-mailer.com",
    messaging_uri: "https://messaging.go-mailer.com",
    users_uri: "https://users.go-mailer.com",
    sales_uri: "https://sales.go-mailer.com",
    api_key: "R28tTWFpbGVyLTQ5NTExMjgwOTU1OC41NDI4LTQw",
    audience_id: 401695728706439,
  },
};

export default config[REACT_APP_ENV];
