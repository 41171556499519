import React from "react";
import styles from "./review.module.css";
import verivafrica from "assets/images/brands/verivafrica.png";

export const DemoReview = () => {
  return (
    <div className={styles.wrapper}>
      <div>
        <p>
          Our experience with Go-Mailer has been outstanding. We've seen significant improvement and growth since we
          joined the platform.What truly sets Go-Mailer apart, however, is their unwavering commitment to customer
          satisfaction.
        </p>
        <br />
        <p>
          Their customer experience is top-notch, and they demonstrate a genuine willingness to listen to feedback and
          act on it. This level of dedication has made all the difference in our journey with Go-Mailer.
        </p>
      </div>
      <div className={styles.author}>
        <div className={styles.authorIcon}>
          <img src={verivafrica} alt="veriv africa testimonial" />
        </div>
        <div>
          <div className={styles.authorName}>Eniola</div>
          <div className={styles.authorCompany}>Consummate Traders</div>
        </div>
      </div>
    </div>
  );
};
