import React from "react";
import cx from "classnames";
import styles from "./wrapper.module.css";
import generalStyle from "app.module.css";

export const MainFeaturesWrapper = ({ children, className }) => {
  return (
    <section id="features" className={cx(styles.features, className)}>
      <div className={generalStyle.gmContainer}>
        <div className={styles.wrapper}>
          <div className={styles.featureList}>{children}</div>
        </div>
      </div>
    </section>
  );
};
