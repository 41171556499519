import { Partners } from "pages/main/partners";
import { MajorFeature } from "shared/feature/major";
import { LandingPageHero } from "shared/hero";
import data from "./data.js";
import React from "react";
import { MainFeaturesWrapper } from "shared/feature/wrapper";
import { Footer } from "pages/footer";
import { GetStartedSection } from "pages/main/get-started";
import { FAQ } from "shared/faq";
import styles from "./automation.module.css";
import hero_image from "assets/images/landing/automation/automation-main.png";

export const AutomationPage = () => {
  return (
    <>
      <LandingPageHero
        className={styles.wrapper}
        title="Accelerate Your Conversions with Efficient Marketing Automation"
        description="Save time, cultivate lasting customer relationships, and drive measurable revenue with powerful marketing automation across multiple channels - emails, SMS, and push."
        image={hero_image}
      />
      <Partners />
      <MainFeaturesWrapper className={styles.features}>
        {(data.features || []).map((feature) => (
          <MajorFeature key={feature.tagline} {...feature} />
        ))}
      </MainFeaturesWrapper>
      <FAQ data={data.faq || []} />
      <GetStartedSection />
      <Footer />
    </>
  );
};
