import React from "react";
import cx from "classnames";
import styles from "./input.module.css";

export const Checkbox = ({ label = "", input_id = "", checked = false, onClick = () => {}, checkboxClass }) => {
  const handleClick = () => {
    onClick();
  };

  return (
    <label className={styles.checkboxWrapper}>
      <input id={input_id} type="checkbox" checked={checked} name="products" onClick={handleClick} />
      <span className={cx(styles.gmCheckbox, checkboxClass)}></span>
      {label}
    </label>
  );
};
