import React from "react";
import cx from "classnames";
import styles from "./button.module.css";
import Spinner from "shared/spinners/spinner-15/spinner-15";
import { Icon } from "assets/icons";

export const Button = ({
  className = "",
  icon_name = "",
  text = "",
  onClick = () => {},
  type = "primary", // 'primary' | 'secondary'
  loading,
  disabled = false,
}) => {
  const btn_type = `btn_${type}`;

  return (
    <button
      className={cx(styles.button, styles[btn_type], className)}
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
      disabled={loading || disabled}
    >
      {icon_name && (
        <span className={styles.icon}>
          {" "}
          <Icon name={icon_name} />{" "}
        </span>
      )}
      <span className={styles.text}>{text}</span>
      {loading && (
        <span className={styles.spinner}>
          <Spinner />
        </span>
      )}
    </button>
  );
};

export const LinkButton = ({ className = "", text, icon_name = "arrow_right_flat", path = "" }) => {
  const handleClick = () => {
    window.location.href = path;
  };

  return (
    <div className={cx(styles.linkBtn, className)} onClick={handleClick}>
      <span>{text}</span>
      <Icon name={icon_name} />
    </div>
  );
};
