import cx from "classnames";
import { LandingBtn } from "elements/buttons";
import React from "react";
import styles from "./hero.module.css";
import generalStyle from "app.module.css";

export const LandingPageHero = ({
  className = "",
  location = "main",
  title = "Welcome to our page",
  description = "Lorem ipsum dolor met islo kuk a deim ded nade nidnde ndekndedn nasdsan dnadsa nadsdad nadsddee adafaf adsdefeefsa daede",
  image = null,
}) => {
  return (
    <main className={cx(styles.main, className)}>
      <div className={cx(generalStyle.gmContainer, styles.layout)}>
        <div className={styles.mainWrapper}>
          <div className={styles.mainText}>
            <div className={styles.headingTitle}>
              <h1>{title}</h1>
            </div>
            <div className={styles.tagline}>{description}</div>
          </div>
          <div className={styles.ctaWrapper}>
            <LandingBtn location={location} />
          </div>
        </div>
        <div className={styles.landingImage}>
          <img src={image} alt="Landing page hero" />
        </div>
      </div>
    </main>
  );
};
