import { Icon } from "assets/icons";
import React, { useEffect, useRef, useState } from "react";
import styles from "./web.module.css";

export const WebMenu = ({ children, label, parent = null }) => {
  const wrapper_ref = useRef(null);
  const [show_content, setShowContent] = useState(false);

  useEffect(() => {
    if (!parent || !wrapper_ref) return;

    const parent_height = parent.current.clientHeight;
    wrapper_ref.current.style.top = `${parent_height}px`;
  }, [parent, wrapper_ref]);

  const toggleMenuDisplay = () => {
    wrapper_ref.current.style.display = show_content ? "none" : "block";
    setShowContent((show) => !show);
  };

  return (
    <>
      <div className={styles.label} onClick={toggleMenuDisplay}>
        <span>{label}</span>
        <Icon width={16} height={16} name={show_content ? "collapse" : "expand"} />
      </div>
      <div ref={wrapper_ref} className={styles.wrapper}>
        <div className={styles.contentWrapper}>
          <div className={styles.content}>{children}</div>
        </div>
      </div>
    </>
  );
};
