import React, { useEffect, useState } from "react";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import styles from "app.module.css";
import { Header } from "pages/header";
import { Main } from "pages/main/main";
import { Pricing } from "pages/main/pricing";
import { Reviews } from "pages/main/reviews";
import { Footer } from "pages/footer";
import { PrivacyPolicy } from "pages/privacy";
import { TermsAndConditions } from "pages/terms";
import { ContactSection } from "pages/main/contact";
import { Unsubscribe } from "pages/unsubscribe";
import Documentation from "developers";
import { Partners } from "pages/main/partners";
import { useQuery } from "hooks/query";
import { DemoPage } from "pages/demo";
import { ConvertEngageRetain } from "pages/main/features/convert";
import { TransactionalFeature } from "pages/main/features/transactional";
import { InsightsFeature } from "pages/main/features/insights";
// import { Whitepaper } from "pages/main/whitepaper";
import { GetStartedSection } from "pages/main/get-started";
import { ProductSections } from "pages/products";

const MainLayout = () => (
  <>
    <Main />
    <Partners />
    <ConvertEngageRetain />
    <TransactionalFeature />
    <InsightsFeature />
    <Reviews />
    {/* <Whitepaper /> */}
    <Pricing />
    <GetStartedSection />
    <Footer />
  </>
);

const AppSections = () => {
  const [, setShowLogo] = useState(true);
  return (
    <div className={styles.pageWrapper}>
      <Header />
      <Routes>
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/contact" element={<ContactSection />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/products/*" element={<ProductSections />} />
        <Route path="/terms" element={<TermsAndConditions />} />
        <Route path="/unsubscription/:tracking_id/:email/:id" element={<Unsubscribe setShowLogo={setShowLogo} />} />
        <Route path="*" element={<MainLayout />} />
      </Routes>
    </div>
  );
};

export const App = () => {
  const query = useQuery();

  useEffect(() => {
    if (!Object.keys(query).length) return;
    window.localStorage.setItem("utm", window.location.search.slice(1));
  }, [query]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/developers/*" element={<Documentation />} />
        <Route path="/demo" element={<DemoPage />} />
        <Route path="*" element={<AppSections />} />
      </Routes>
      <Outlet />
    </BrowserRouter>
  );
};
