import useScreenSize from "hooks/size";
import React from "react";

export const GridColumn = ({ span = 1, auto_columns = true, children }) => {
  const wrapper_style = {
    gridColumnEnd: `span ${span}`,
    gridAutoColumns: auto_columns && "max-content",
    width: "100%",
  };

  return <div style={wrapper_style}>{children}</div>;
};

export const GridRow = ({
  num_of_columns = 4,
  auto_flow = true,
  children,
  mobileStyles = {},
  tabletStyles = {},
  preserveDefaultStyle = false,
}) => {
  const { isMobile, isTablet } = useScreenSize();

  const wrapper_style = {
    display: "grid",
    gridTemplateColumns: isMobile && !preserveDefaultStyle ? "1fr" : `repeat(${num_of_columns}, 1fr)`,
    gridAutoRows: auto_flow && "max-content",
    gridAutoFlow: "dense",
    gap: !isMobile && "20px",
    rowGap: isMobile && "20px",
    width: "100%",
    ...(isMobile ? mobileStyles : {}),
    ...(isTablet ? tabletStyles : {}),
  };

  return <div style={wrapper_style}>{children}</div>;
};
