import React from "react";
import generalStyle from "app.module.css";
import styles from "./convert.module.css";
import { GridColumn, GridRow } from "shared/layouts/grid";
import { Spacer } from "shared/layouts/generic";
import { HeaderLabel, HeadingTwo } from "shared/text/header";
import { Paragraph } from "shared/text/paragraph";
import { Feature } from "shared/feature";

import campaigns from "assets/images/landing/campaigns.png";
import automation from "assets/images/landing/automation.png";
import reports from "assets/images/landing/reports.png";
import segmentation from "assets/images/landing/segmentation.png";

export const ConvertEngageRetain = () => {
  return (
    <div className={styles.wrapper}>
      <div className={generalStyle.gmContainer}>
        <div className={styles.header}>
          <HeaderLabel text="Convert. Engage. Retain" />
          <HeadingTwo text="Build meaningful connections that turn prospects into loyal customers." />
          <Paragraph
            text="
            Deliver timely, relevant, and personalised messages that guide your prospects from interest to action to
            lifetime advocacy. Build trust and nurture relationships, one message at a time."
            size="medium"
          />
        </div>
        <div>
          <GridRow num_of_columns={5}>
            <GridColumn span={3}>
              <Feature
                title="Campaign Messaging"
                description="Send important updates, promotions, and personalised messages through email, SMS, and push notifications - all in one platform. With high inbox delivery, and in-depth analytics, ensure your messages get seen, drive engagement, and boost conversions."
                image={campaigns}
              />
            </GridColumn>
            <GridColumn span={2}>
              <Feature
                title="Marketing Automation"
                description="Engage customers and drive conversions with automated, personalised messaging. Deliver the right message at the right time - to the right customer."
                image={automation}
              />
            </GridColumn>
          </GridRow>
          <Spacer multiple={4} />
          <GridRow num_of_columns={5}>
            <GridColumn span={2}>
              <Feature
                title="Advanced Segmentation"
                description="Segment your users by behaviour, custom attributes, and events to deliver relevant, relatable and high-impact messages that boost engagement and conversions."
                image={segmentation}
              />
            </GridColumn>
            <GridColumn span={3}>
              <Feature
                title="In-depth Performance Reports"
                description="Track, analyse, and optimise your campaigns with detailed performance insights and real-time data. Understand what works, refine your strategy, and drive better engagement, conversions, and long-term business growth."
                image={reports}
              />
            </GridColumn>
          </GridRow>
        </div>
      </div>
    </div>
  );
};
