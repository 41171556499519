import { Partners } from "pages/main/partners";
import { MajorFeature } from "shared/feature/major";
import { LandingPageHero } from "shared/hero";
import data from "./data.js";
import React from "react";
import { MainFeaturesWrapper } from "shared/feature/wrapper";
import { Footer } from "pages/footer";
import { GetStartedSection } from "pages/main/get-started";
import { FAQ } from "shared/faq";
import styles from "./campaign.module.css";
import main_image from "assets/images/landing/campaign/campaign-main.png";

export const CampaignPage = () => {
  return (
    <>
      <LandingPageHero
        className={styles.wrapper}
        image={main_image}
        title="Reach More Inboxes, Drive Higher Revenue"
        description="Reach more customers and drive higher engagement with powerful campaign messaging. Enjoy high inbox delivery rates, advanced personalization, and omni-channel reach across email, SMS, and push - all in one platform."
      />
      <Partners />
      <MainFeaturesWrapper className={styles.features}>
        {(data.features || []).map((feature) => (
          <MajorFeature key={feature.icon} {...feature} />
        ))}
      </MainFeaturesWrapper>
      <FAQ data={data.faq || []} />
      <GetStartedSection />
      <Footer />
    </>
  );
};
