import React from "react";
import cx from "classnames";
import styles from "./main.module.css";
import generalStyle from "app.module.css";
import landing_image from "assets/images/landing-main.png";
import { LandingBtn } from "elements/buttons";

export const Main = () => {
  return (
    <main className={styles.main}>
      <div className={cx(generalStyle.gmContainer, styles.layout)}>
        <div className={styles.mainWrapper}>
          <div className={styles.mainText}>
            <div className={styles.headingTitle}>
              <h1>Convert, Engage & Retain Customers From One Platform.</h1>
            </div>
            <div className={styles.tagline}>
              Unlock greater levels of revenue growth. Drive deeper customer engagement, increase conversions, and build
              lasting relationships - all with one powerful, easy-to-use marketing platform.
            </div>
          </div>
          <div className={styles.ctaWrapper}>
            <LandingBtn location="main" />
          </div>
        </div>
        <div className={styles.landingImage}>
          <img src={landing_image} alt="Landing page dashboard" />
        </div>
      </div>
    </main>
  );
};
