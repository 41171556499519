import logo from "assets/icons/logo-new.png";
import { Icon } from "assets/icons";
import styles from "./demo.module.css";
import React from "react";
import { DemoForm } from "./form";
import { Button } from "elements/buttons";
import config from ".config.js";

import cowry from "assets/svg/brands/cowry-transparent.svg";
import lagride from "assets/svg/brands/lagride-transparent.svg";
import credpal from "assets/svg/brands/credpal-transparent.svg";
import sycamore from "assets/svg/brands/sycamore-transparent.svg";
import { DemoReview } from "./review";

const partnerLogos = [
  { src: credpal, alt: "credpal" },
  { src: cowry, alt: "cowry" },
  { src: sycamore, alt: "sycamore" },
  { src: lagride, alt: "lagos-ride" },
];

const Benefit = ({ value }) => {
  return (
    <div className={styles.benefit}>
      <Icon className={styles.icon} width={20} height={20} name="check" />
      <span>{value}</span>
    </div>
  );
};

export const DemoPage = () => {
  const benefits = [
    "Increase your engagement & conversions",
    "Automate your outreaches with ease",
    "Optimise campaign performance with detailed reports",
    "Turn boring transactional emails into cross-sell opportunities",
    "Stay Secure & Scalable",
    "Enjoy excellent customer service",
  ];

  const handleLogin = () => {
    window.location = `${config.app_url}/login`;
  };

  return (
    <div className={styles.wrapper}>
      <header>
        <div className={styles.logo} onClick={() => (window.location = "/")}>
          <img src={logo} alt="App Logo" />
        </div>
        <div>
          <Button text="Log In" className={styles.loginBtn} onClick={handleLogin} />
        </div>
      </header>
      <div className={styles.body}>
        <div className={styles.formWrapper}>
          <h1>Schedule a Demo</h1>
          <p>
            Discover how our all-in-one multi-channel marketing platform helps you engage, convert, and retain customers
            effortlessly.
          </p>
          <DemoForm />
        </div>
        <div className={styles.infoWrapper}>
          <div>
            <h2>
              Experience Smarter Multi-Channel Marketing with <span className={styles.blue}>Go-Mailer</span>
              <span className={styles.orange}>.</span>
            </h2>
            {benefits.map((benefit, index) => (
              <Benefit key={`benefit-${index}`} value={benefit} />
            ))}
          </div>
          <div>
            <h5>Trusted by Amazing brands like yours.</h5>
            <div className={styles.partners}>
              {partnerLogos.map((partner_logo) => (
                <span className={styles.partnerLogo} key={partner_logo.alt}>
                  <img alt={partner_logo.alt} src={partner_logo.src} />
                </span>
              ))}
            </div>
            <div>
              <DemoReview />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
