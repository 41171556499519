import React from "react";
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from "react-bootstrap";
import styles from "./faq.module.css";
import generalStyle from "app.module.css";

export const FAQ = ({ data = [] }) => {
  return (
    <div className={styles.wrapper}>
      <div className={generalStyle.gmContainer}>
        <h3>Frequently Asked Questions</h3>
        <Accordion>
          {data.map((datum, index) => (
            <AccordionItem key={datum.question} eventKey={index + 1}>
              <AccordionHeader>{datum.question}</AccordionHeader>
              <AccordionBody className={styles.accordionBody}>{datum.answer}</AccordionBody>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
};
