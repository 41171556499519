import visualize from "assets/images/landing/analytics/visualize.png";
import compare from "assets/images/landing/analytics/compare.png";
import history from "assets/images/landing/analytics/history.png";
import website from "assets/images/landing/analytics/website.png";

const data = {
  features: [
    {
      icon: visualize,
      tagline: "Visualization",
      title: "See How Your Users Adopt & Use Features Over Time.",
      description:
        "Track your application usage over time to uncover key customer engagement trends. Analyze product utilization with real-time insights to refine your strategies, improve products, and enhance customer retention.",
      benefits: ["Better customer understanding", "Excellent product delivery", " Increased customer retention"],
      bgColor: "#FFBF6033",
      color: "#FFBF60",
      href: "https://app.go-mailer.com/register",
      cta: "Get started free",
    },
    {
      icon: compare,
      tagline: "compare events",
      title: "Compare Usage Patterns to Spot Trends & Growth Opportunities",
      description:
        "Quickly spot product launch failures, identify conversion patterns, and optimize your strategies with real-time usage comparison. Drive product adoption with real data - not guesswork.",
      benefits: ["Reduced churn", "Optimized growth strategies", "Increased product success"],
      bgColor: "#2A99FF33",
      color: "#2A99FF",
      href: "https://app.go-mailer.com/register",
      cta: "Get started free",
      direction: "rtl",
    },
    {
      icon: history,
      tagline: "history",
      title: "Gain Deeper Insights with Customized Usage Reports",
      description:
        "Analyze product performance from every angle, refine strategies, and boost conversions with customized reports. Build dynamic, insightful reports tailored to gender, location, and key customer attributes—turn raw data into powerful business decisions",
      benefits: ["Enhanced Personalisation", "Improved conversion rates", "Maximized revenue"],
      bgColor: "#66B10733",
      color: "#66B107",
      href: "https://app.go-mailer.com/register",
      cta: "Get started free",
    },
    {
      icon: website,
      tagline: "website analytics",
      title: "Track Website Visits, Clicks & Interactions",
      description:
        "Monitor button clicks, page views, and session duration in real time. Understand how your visitors interact with your content and optimize for better engagement and higher revenue.",
      benefits: ["Reduced bounce", "Insightful traffic analytics", "Increased visitor conversion"],
      bgColor: "#2A99FF33",
      color: "#2A99FF",
      href: "https://app.go-mailer.com/register",
      cta: "Get started free",
      direction: "rtl",
    },
  ],
  faq: [
    {
      question: "How can I use app insights to reduce churn and improve customer retention?",
      answer:
        "By tracking how users adopt and use your features over time, you can identify engagement patterns, spot users at risk of churning, and proactively re-engage them. You’ll also see which features drive the most value, helping you refine your product and messaging to keep customers engaged longer.",
    },
    {
      question: "Can I track customer behavior across different segments, like location or device type?",
      answer:
        "Our customized usage reports allow you to analyze customer behavior based on location, device type, gender, engagement level, and other key attributes. This helps you personalize your marketing, improve targeting, and refine your product offerings to better meet customer needs.",
    },
    {
      question: "How can I tell if a product launch or campaign is successful?",
      answer:
        "Our platform lets you compare usage patterns and product performance in real time. If a new feature or marketing campaign isn’t driving engagement as expected, you’ll be able to spot issues early, adjust your strategy, and optimize for better results, all based on actual user behavior.",
    },
  ],
};

export default data;
