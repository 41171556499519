import React from "react";
import { Route, Routes } from "react-router-dom";
import { AnalyticsPage } from "./analytics";
import { AutomationPage } from "./automation";
import { CampaignPage } from "./campaigns";
import { TransactionalPage } from "./transactional";

export const ProductSections = () => {
  return (
    <Routes>
      <Route path="/analytics" element={<AnalyticsPage />} />
      <Route path="/automations" element={<AutomationPage />} />
      <Route path="/transactionals" element={<TransactionalPage />} />
      <Route path="/*" element={<CampaignPage />} />
    </Routes>
  );
};
