import React from "react";
import cx from "classnames";
import styles from "./partner.module.css";
import Slider from "react-slick";
import consummate from "assets/svg/brands/consummate-transparent.svg";
import avon from "assets/svg/brands/avon-transparent.svg";
import cowry from "assets/svg/brands/cowry-transparent.svg";
import lagride from "assets/svg/brands/lagride-transparent.svg";
import verivafrica from "assets/svg/brands/verivafrica-transparent.svg";
import tangerine from "assets/svg/brands/tangerine-transparent.svg";
import shipafrica from "assets/svg/brands/ship-africa-transparent.svg";
import advantar from "assets/svg/brands/advantar-transparent.svg";
import credpal from "assets/svg/brands/credpal-transparent.svg";
import rainoil from "assets/svg/brands/rainoil-transparent.svg";
import vasrefil from "assets/svg/brands/vasrefil-transparent.svg";
import sycamore from "assets/svg/brands/sycamore-transparent.svg";
import generalStyle from "app.module.css";

import SwiperCore, { Navigation, Pagination, Mousewheel } from "swiper";
import "swiper/swiper.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

SwiperCore.use([Navigation, Pagination, Mousewheel]);

const partnerLogos = [
  { src: avon, alt: "avon" },
  { src: credpal, alt: "credpal" },
  { src: cowry, alt: "cowry" },
  { src: sycamore, alt: "sycamore" },
  { src: lagride, alt: "lagos-ride" },
  { src: rainoil, alt: "rainoil" },
  { src: verivafrica, alt: "verivafrica" },
  { src: tangerine, alt: "tangerine" },
  { src: shipafrica, alt: "shipafrica" },
  { src: consummate, alt: "consummate" },
  { src: advantar, alt: "advantar" },
  { src: vasrefil, alt: "vasrefil" },
];
const settings = {
  className: styles.slider,
  cssEase: "linear",
  dots: true,
  dotsClass: styles.dots,
  arrows: false,
  infinite: true,
  speed: 2000,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
      },
    },
  ],
};

export const Partners = () => {
  return (
    <div className={styles.partnersSection}>
      <div className={cx(generalStyle.gmContainer, styles.wrapper)}>
        {/* <div className={styles.title}>Trusted by over 100 companies</div> */}
        <div>
          <Slider {...settings}>
            {partnerLogos.map((brand, index) => (
              <div key={index} className={styles.slide}>
                <img src={brand.src} alt={brand.alt} className={styles.brand} />
              </div>
            ))}
          </Slider>
          <div className={styles.overlay} />
        </div>
      </div>
    </div>
  );
};
