const nav_items = [
  {
    icon: "campaign",
    title: "Broadcast Messaging",
    description: "Experience 99.9% inbox delivery on your campaigns - not spam. Reach your audience when it matters",
    link: "/products/campaign",
  },
  {
    icon: "automation",
    title: "Marketing Automation",
    description: "Experience 99.9% inbox delivery on your campaigns - not spam. Reach your audience when it matters",
    link: "/products/automations",
  },
  // {
  //   icon: "people",
  //   title: "Customer Management",
  //   description: "Experience 99.9% inbox delivery on your campaigns - not spam. Reach your audience when it matters",
  //   link: "/products/subscribers",
  // },
  {
    icon: "transactional",
    title: "Transactional Emails",
    description: "Experience 99.9% inbox delivery on your campaigns - not spam. Reach your audience when it matters",
    link: "/products/transactionals",
  },
  {
    icon: "dashboard",
    title: "App Analytics",
    description: "Experience 99.9% inbox delivery on your campaigns - not spam. Reach your audience when it matters",
    link: "/products/analytics",
  },
];
export default nav_items;
