import channels from "assets/images/landing/automation/channels.png";
import custom from "assets/images/landing/automation/custom.png";
import hooked from "assets/images/landing/automation/hooked.png";
import visual_builder from "assets/images/landing/automation/visual-builder.png";

const data = {
  features: [
    {
      icon: visual_builder,
      tagline: "visual-builder",
      title: "Place Your Revenue Growth on Autopilot",
      description:
        "Say goodbye to manual work! Design powerful, automated drip campaigns with Go-Mailer’s visual builder. Nurture your leads, drive engagement, and effortlessly scale your business.",
      benefits: ["Increased time savings", "Reduced human error", "Improved customer experience"],
      bgColor: "#FFBF6033",
      color: "#FFBF60",
      href: "https://app.go-mailer.com/register",
      cta: "Get started free",
    },
    {
      icon: hooked,
      tagline: "hooked",
      title: "Turn Unsure Prospects into Loyal Customers",
      description:
        "Build trust and turn your leads into loyal customers with personalized, perfectly-timed email sequences. Automate follow-ups and onboarding emails to keep your audience engaged and primed for action.",
      benefits: ["Stronger customer relationships", "Increased conversions", "Increased customer advocacy"],
      bgColor: "#2A99FF33",
      color: "#2A99FF",
      href: "https://app.go-mailer.com/register",
      cta: "Get started free",
      direction: "rtl",
    },
    {
      icon: custom,
      tagline: "custom-event",
      title: "Trigger Smart Automation from Your App Events",
      description:
        "Your automation, your rules! Trigger and respond to unique, custom events based on user behavior, product interactions, or any data point that matters. Grow your business with pin-point precision.",
      benefits: ["Efficient lead nurturing", "Real-time customer engagement", "Hyper-personalized user experiences"],
      bgColor: "#66B10733",
      color: "#66B107",
      href: "https://app.go-mailer.com/register",
      cta: "Get started free",
    },
    {
      icon: channels,
      tagline: "channels",
      title: "Reach Customers Everywhere with Multi-Channel Automation",
      description:
        "Go beyond email; connect with customers instantly via SMS and push. Engage, convert, and drive higher revenue from one powerful platform designed for seamless multi-channel marketing.",
      benefits: ["Greater reach & visibility", "Increased customer retention", "Optimized marketing performance"],
      bgColor: "#2A99FF33",
      color: "#2A99FF",
      href: "https://app.go-mailer.com/register",
      cta: "Get started free",
      direction: "rtl",
    },
  ],
  faq: [
    {
      question: "Can I customize automation triggers based on my customer’s actions?",
      answer:
        "Yes! Our event-triggered automation lets you respond to specific customer behaviors, such as visiting a product page, making a purchase, or abandoning a cart. You can create custom workflows that send tailored messages at the right moment, boosting your engagement and maximizing revenue opportunities.",
    },
    {
      question: "Can I automate follow-ups for leads who don’t respond?",
      answer:
        "Yes, you can set up automated follow-up sequences that trigger based on customer behavior. For example, if a lead doesn’t open your email or complete a purchase, you can automatically send a follow-up message with a different subject line, a special offer, or a reminder. This helps keep your leads engaged, improve response rates, and increase your conversions without manual outreach.",
    },
    {
      question: "How do I track the performance of my automated campaigns?",
      answer:
        "Our platform provides real-time analytics so you can track open rates, clicks, conversions, and customer interactions across email, SMS, and push. With these insights, you can optimize your messaging, test different strategies, and continuously improve your campaign performance to maximize your ROI.",
    },
  ],
};

export default data;
